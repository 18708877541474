import { Box, Button, FormControl, IconButton, Popover, Typography, Stack, ListSubheader } from '@mui/material';
import {
    type GenericFilter,
    type CohortFilter,
    type UsersAdminFilterData,
    type ProgramFilter,
} from 'Admin/Admin.types';
import { TextField, Autocomplete } from 'FrontRoyalMaterialUiForm';
import { useForm, FormProvider, useFormContext, useFieldArray } from 'FrontRoyalReactHookForm';
import { type SetStateAction, type Dispatch, type MouseEvent, type SyntheticEvent, useState } from 'react';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { PROGRAM_TYPE_OPTION_GROUPS, PROGRAM_TYPE_OPTION_GROUP_OTHER } from 'Admin/helpers';
import { type ProgramType, ProgramTypeConfigs } from 'Program';
import moize from 'moize';
import { type AnyObject } from '@Types';
import { StatusOptions, StatusOptionsById } from './StatusOptions';
import { type StatusKey } from './AdminUsers.types';

type FiltersProps = {
    initialValues: Partial<typeof defaultValues> | undefined;
    usersAdminFilterData: UsersAdminFilterData;
    resetFiltersCallback: () => void;
    searchCallback: (values: Partial<typeof defaultValues>) => void;
};
type AdminUsersOption = { label: string; value: string };
type AdminUsersGroupedOption = AdminUsersOption & { optionGroupPriority: string };
type ProgramRow = { program_type: ProgramType[]; cohort_id: string[]; status: StatusKey[] };

export const defaultProgramRow: ProgramRow = { program_type: [], cohort_id: [], status: [] };
export const defaultValues = {
    generic_search: '',
    program_rows: [defaultProgramRow] as ProgramRow[],
    name: '',
    email: '',
    id: '',
    city_state_country: '',
    phone: '',
    career_profile_keyword_search: '',
    deferred_from_cohort_id: [] as string[],
    deferred_into_cohort_id: [] as string[],
    group_name: [] as string[],
    role_id: [] as string[],
    institution_id: [] as string[],
};

const advancedFilterKeys: Partial<keyof typeof defaultValues>[] = [
    'name',
    'email',
    'id',
    'city_state_country',
    'phone',
    'career_profile_keyword_search',
    'deferred_from_cohort_id',
    'deferred_into_cohort_id',
    'group_name',
    'role_id',
    'institution_id',
];

const maxProgramRows = 3;
const statusPlaceholder = 'Status(es)';

const getProgramOptions = moize((availableActivePrograms: ProgramFilter[]) =>
    availableActivePrograms.map(p => {
        const config = ProgramTypeConfigs[p.id];
        const optionGroup = PROGRAM_TYPE_OPTION_GROUPS.find(g => g.filter(config)) || PROGRAM_TYPE_OPTION_GROUP_OTHER;
        return {
            label: p.program_abbreviation,
            value: p.id,
            optionGroupPriority: `${optionGroup.priority.toString()}-${optionGroup.label}`,
        };
    }),
);
export const getCohortOptions = moize(
    (availableCohorts: CohortFilter[], programType: ProgramType[] = [], cohortId: string[] = []) =>
        availableCohorts
            .filter(c =>
                // Filter by selected programs, but keep a particular cohort(s) if it's already selected
                programType.length ? programType.includes(c.program_type) || cohortId.includes(c.id) : true,
            )
            .map(c => {
                const config = ProgramTypeConfigs[c.program_type];
                const optionGroup =
                    PROGRAM_TYPE_OPTION_GROUPS.find(g => g.filter(config)) || PROGRAM_TYPE_OPTION_GROUP_OTHER;
                const optionGroupTitle = c.program_abbreviation;
                return {
                    label: c.name,
                    value: c.id,
                    optionGroupPriority: `${optionGroup.priority.toString()}-${optionGroupTitle}`,
                };
            }),
);
const getGroupOptions = moize((availableAccessGroups: GenericFilter[]) =>
    availableAccessGroups.map(g => ({ label: g.name, value: g.name })),
);
const getRoleOptions = moize((availableRoles: GenericFilter[]) =>
    availableRoles.map(r => ({ label: r.name, value: r.id })),
);
const getInstitutionOptions = moize((availableInstitutions: GenericFilter[]) =>
    availableInstitutions.map(i => ({ label: i.name, value: i.id })),
);
export const trimValues = (values: AnyObject) =>
    // Trim a full form object into just the values that are set
    // Check this AnyObject usage after https://trello.com/c/02dp1oRz
    Object.entries(values).reduce<AnyObject>((acc, [key, value]) => {
        if (key === 'program_rows') {
            const trimmedProgramRows = (values[key] as ProgramRow[])
                .filter((row: ProgramRow) => row.program_type.length || row.cohort_id.length || row.status.length)
                .map(row => trimValues(row));

            if (trimmedProgramRows.length) acc[key] = trimmedProgramRows;
        } else if (Array.isArray(value) && value.length) {
            acc[key] = value;
        } else if (!Array.isArray(value) && value) {
            acc[key] = value;
        }
        return acc;
    }, {}) as Partial<typeof defaultValues>;

export default function Filters({
    initialValues,
    usersAdminFilterData,
    resetFiltersCallback,
    searchCallback,
}: FiltersProps) {
    // Expand the trimmed initial values into a full form object
    initialValues = { ...defaultValues, ...initialValues };
    initialValues.program_rows = initialValues.program_rows!.map(row => ({ ...defaultProgramRow, ...row }));

    const formFunctions = useForm({ defaultValues: initialValues });

    const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(
        () => !!advancedFilterKeys.filter(k => initialValues?.[k]?.length ?? !!initialValues?.[k]).length,
    );

    return (
        <FormProvider {...formFunctions}>
            <form
                onSubmit={formFunctions.handleSubmit(values => searchCallback(trimValues(values)))}
                style={{ padding: 16 }}
            >
                <div style={{ display: 'inline-block', verticalAlign: 'top', marginRight: 24, marginBottom: 16 }}>
                    <h3>User</h3>
                    <TextField
                        name="generic_search"
                        label="Search"
                        placeholder="Name / Email / ID"
                        variant="standard"
                        sx={{ width: 250, marginRight: 2 }}
                    />
                </div>

                <div style={{ display: 'inline-block' }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <h3 style={{ marginRight: 8 }}>Program State</h3>
                        <InfoPopover
                            text={`Add multiple filter rows to refine your search (max ${maxProgramRows}). All filter rows must match a user for them to be shown in the search results below.`}
                        />
                    </Stack>
                    <ProgramRows usersAdminFilterData={usersAdminFilterData} />
                </div>

                {advancedFiltersOpen && <AdvancedFilters usersAdminFilterData={usersAdminFilterData} />}

                <div>
                    <FormActions
                        advancedFiltersOpen={advancedFiltersOpen}
                        setAdvancedFiltersOpen={setAdvancedFiltersOpen}
                        resetFiltersCallback={resetFiltersCallback}
                    />
                </div>
            </form>
        </FormProvider>
    );
}

function ProgramRows({ usersAdminFilterData }: { usersAdminFilterData: UsersAdminFilterData }) {
    const { control, watch } = useFormContext<{ program_rows: ProgramRow[] }>();
    const watchedValues = watch('program_rows', []);
    const { fields, append, remove } = useFieldArray({ control, name: 'program_rows' });

    const programRows = fields.map((input, index) => ({ ...input, ...watchedValues[index] }));
    const programTypeOptions = getProgramOptions(usersAdminFilterData.availableActivePrograms);
    const cohortOptions = (index: number) =>
        getCohortOptions(
            usersAdminFilterData.availableCohorts,
            programRows[index].program_type,
            programRows[index].cohort_id,
        );

    return (
        <>
            {programRows.map((programRow, i) => (
                <div key={programRow.id}>
                    <div style={{ display: 'inline-block', marginBottom: 16 }}>
                        <AdminUsersGroupedAutocomplete
                            label="Program(s)"
                            field={`program_rows[${i}].program_type`}
                            options={programTypeOptions}
                            width={250}
                        />

                        <AdminUsersGroupedAutocomplete
                            label="Cohort(s)"
                            field={`program_rows[${i}].cohort_id`}
                            options={cohortOptions(i)}
                            width={250}
                        />

                        <Status index={i} />
                    </div>
                    <div style={{ display: 'inline-block', verticalAlign: 'bottom', marginBottom: 16, minWidth: 222 }}>
                        {i < programRows.length - 1 ? (
                            <Typography
                                display="inline"
                                sx={{ width: 150, marginRight: 2, display: 'inline-block', opacity: 0.6 }}
                            >
                                AND...
                            </Typography>
                        ) : (
                            <AddProgramRow
                                onClick={() => {
                                    append(defaultProgramRow);
                                }}
                                disabled={programRows.length === maxProgramRows}
                            />
                        )}

                        {!!i && (
                            <IconButton onClick={() => remove(i)} sx={{ marginRight: 2 }}>
                                <DeleteOutlineIcon color="error" />
                            </IconButton>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
}

function Status({ index }: { index: number }) {
    const { watch, setFieldValue } = useFormContext<{ program_rows: ProgramRow[] }>();
    const status = watch(`program_rows.${index}.status`);
    const [expandedItems, setExpandedItems] = useState<StatusKey[]>(status);

    const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handlePopoverClick = ({ currentTarget }: MouseEvent<HTMLButtonElement>) => setPopoverAnchorEl(currentTarget);
    const handlePopoverClose = () => setPopoverAnchorEl(null);

    const handleStatusToggle = (_: SyntheticEvent, toggledStatus: string, isSelected: boolean) => {
        const updatedStatus = getUpdatedStatus(toggledStatus as StatusKey, isSelected, status);
        setFieldValue(`program_rows.${index}.status`, updatedStatus);
        setExpandedItems(expandedItems.concat(updatedStatus));
    };
    const statusLabel = getStatusLabel(status);

    return (
        <>
            <Button
                onClick={handlePopoverClick}
                endIcon={<ArrowDropDownIcon sx={{ marginTop: 2 }} />}
                color="inherit"
                variant="text"
                sx={{
                    width: 250,
                    height: 48,
                    textTransform: 'none',
                    fontSize: '1rem',
                    marginRight: 4,
                    opacity: statusLabel === statusPlaceholder ? 0.6 : 1.0,
                    justifyContent: 'flex-start',
                    '.MuiButton-endIcon': { marginLeft: 'auto' },
                    borderBottom: 1,
                    borderRadius: 0,
                }}
            >
                <Typography noWrap sx={{ marginTop: 2 }}>
                    {statusLabel}
                </Typography>
            </Button>
            <Popover
                open={!!popoverAnchorEl}
                anchorEl={popoverAnchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Box sx={{ minWidth: 290, paddingTop: 1, paddingBottom: 1 }}>
                    <RichTreeView
                        items={StatusOptions}
                        multiSelect
                        checkboxSelection
                        onItemSelectionToggle={handleStatusToggle}
                        selectedItems={status}
                        expandedItems={expandedItems}
                        onExpandedItemsChange={(_, itemIds) => setExpandedItems(status.concat(itemIds as StatusKey[]))}
                        sx={{
                            '.Mui-selected': { backgroundColor: 'transparent' },
                            '.MuiTreeItem-content': { height: 32 },
                            '.MuiTreeItem-groupTransition': { paddingLeft: 0 },
                            '.MuiTreeItem-groupTransition .MuiTreeItem-content': { paddingLeft: 3 },
                        }}
                    />
                </Box>
            </Popover>
        </>
    );
}

function AddProgramRow({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    return (
        <Button
            type="button"
            onClick={onClick}
            disabled={disabled}
            startIcon={<AddIcon />}
            variant="outlined"
            color="primary"
            sx={{ width: 150, marginRight: 2, verticalAlign: 'bottom' }}
        >
            Add Filter
        </Button>
    );
}

function AdvancedFilters({ usersAdminFilterData }: { usersAdminFilterData: UsersAdminFilterData }) {
    const groupOptions = getGroupOptions(usersAdminFilterData.availableAccessGroups);
    const roleOptions = getRoleOptions(usersAdminFilterData.availableRoles);
    const institutionOptions = getInstitutionOptions(usersAdminFilterData.availableInstitutions);
    const cohortOptions = getCohortOptions(usersAdminFilterData.availableCohorts);

    return (
        <>
            <div style={{ marginTop: 48 }}>
                <h3>Advanced User</h3>
                <TextField
                    name="name"
                    label="Name"
                    placeholder="Contains ..."
                    variant="standard"
                    sx={{ width: 200, marginRight: 2 }}
                />
                <TextField
                    name="email"
                    label="Email"
                    placeholder="Contains ..."
                    variant="standard"
                    sx={{ width: 200, marginRight: 2 }}
                />
                <TextField
                    name="id"
                    label="ID"
                    placeholder="Is ..."
                    variant="standard"
                    sx={{ width: 200, marginRight: 2 }}
                />
                <TextField
                    name="city_state_country"
                    label="Location"
                    placeholder="City / State / Country"
                    variant="standard"
                    sx={{ width: 200, marginRight: 2 }}
                />
                <TextField
                    name="phone"
                    label="Phone"
                    placeholder="Is ..."
                    variant="standard"
                    sx={{ width: 200, marginRight: 2 }}
                />
                <TextField
                    name="career_profile_keyword_search"
                    label="Profile Keyword"
                    placeholder="Contains ..."
                    variant="standard"
                    sx={{ width: 200, marginRight: 2 }}
                />
            </div>

            <div>
                <h3 style={{ marginTop: 48 }}>Advanced Program State</h3>
                <AdminUsersGroupedAutocomplete
                    label="Deferred From"
                    field="deferred_from_cohort_id"
                    options={cohortOptions}
                    width={250}
                />
                <AdminUsersGroupedAutocomplete
                    label="Deferred Into"
                    field="deferred_into_cohort_id"
                    options={cohortOptions}
                    width={250}
                />
            </div>

            <div>
                <h3 style={{ marginTop: 48 }}>Administration</h3>
                <AdminUsersAutocomplete label="Group" field="group_name" options={groupOptions} width={300} />
                <AdminUsersAutocomplete label="Role" field="role_id" options={roleOptions} width={300} />
                <AdminUsersAutocomplete label="Institution" field="institution_id" options={institutionOptions} />
            </div>
        </>
    );
}

function InfoPopover({ text }: { text: string }) {
    const [popoverAnchorEl, setPopoverAnchorEl] = useState<SVGElement | null>(null);
    const handlePopoverHover = ({ currentTarget }: MouseEvent<SVGElement>) => {
        setPopoverAnchorEl(currentTarget);
    };
    const handlePopoverClose = () => {
        setPopoverAnchorEl(null);
    };

    return (
        <>
            <InfoOutlinedIcon
                onMouseEnter={handlePopoverHover}
                onMouseLeave={handlePopoverClose}
                fontSize="small"
                sx={{ opacity: 0.6 }}
            />
            <Popover
                open={!!popoverAnchorEl}
                anchorEl={popoverAnchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                sx={{ pointerEvents: 'none', width: '50%' }}
            >
                <Typography sx={{ p: 1 }}>{text}</Typography>
            </Popover>
        </>
    );
}

function FormActions({
    advancedFiltersOpen,
    setAdvancedFiltersOpen,
    resetFiltersCallback,
}: {
    advancedFiltersOpen: boolean;
    setAdvancedFiltersOpen: Dispatch<SetStateAction<boolean>>;
    resetFiltersCallback: () => void;
}) {
    const { reset, watch } = useFormContext();

    const numAdvanced = watch(advancedFilterKeys).filter(k => k?.length ?? !!k).length;

    const advancedFiltersButtonText = `${advancedFiltersOpen ? 'Hide Advanced Filters' : 'Show Advanced Filters'} ${
        numAdvanced ? `(${numAdvanced})` : ''
    }`;

    return (
        <div style={{ marginTop: 40 }}>
            <Button
                type="button"
                onClick={() => setAdvancedFiltersOpen(v => !v)}
                sx={{ marginRight: 1 }}
                variant="outlined"
            >
                {advancedFiltersButtonText}
            </Button>

            <div style={{ float: 'right' }}>
                <Button
                    type="button"
                    onClick={() => {
                        resetFiltersCallback();
                        reset(defaultValues);
                    }}
                    sx={{ marginRight: 1, opacity: 0.6 }}
                    variant="outlined"
                    color="inherit"
                >
                    Reset
                </Button>
                <Button type="submit" sx={{ marginRight: 1 }} variant="contained">
                    Submit
                </Button>
            </div>
        </div>
    );
}

function AdminUsersGroupedAutocomplete({
    field,
    label,
    options,
    width,
}: {
    field: string;
    label: string;
    options: AdminUsersGroupedOption[];
    width: number;
}) {
    const { watch, setFieldValue } = useFormContext();
    const fieldValue = watch(field);

    return (
        <FormControl sx={{ width, marginRight: 2 }}>
            <Autocomplete
                multiple
                name={field}
                label={label}
                value={options.filter(o => fieldValue.includes(o.value))}
                options={options.sort((a, b) => a.optionGroupPriority.localeCompare(b.optionGroupPriority))}
                groupBy={o => o.optionGroupPriority}
                renderGroup={params => (
                    <div key={params.key}>
                        <ListSubheader sx={{ top: -8, opacity: 0.4 }} disableSticky>
                            {params.group.replace(/^\d*-/, '').toLocaleUpperCase()}
                        </ListSubheader>
                        <Box sx={{ '.MuiAutocomplete-option': { paddingLeft: 4 } }}>{params.children}</Box>
                    </div>
                )}
                isOptionEqualToValue={(o, v) => o.value === v.value}
                onChange={(_, value) => {
                    setFieldValue(
                        field,
                        value.map(v => v.value),
                    );
                }}
                limitTags={1}
                autoHighlight
                variant="standard"
                filterSelectedOptions
                ChipProps={{ size: 'small' }}
                disableCloseOnSelect
            />
        </FormControl>
    );
}

function AdminUsersAutocomplete({
    field,
    label,
    options,
    width = 350,
}: {
    field: string;
    label: string;
    options: AdminUsersOption[];
    width?: number;
}) {
    const { watch, setFieldValue } = useFormContext();
    const fieldValue = watch(field);

    return (
        <FormControl sx={{ width, marginRight: 2 }}>
            <Autocomplete
                multiple
                name={field}
                label={label}
                value={options.filter(o => fieldValue.includes(o.value))}
                options={options}
                isOptionEqualToValue={(o, v) => o.value === v.value}
                onChange={(_, value) => {
                    setFieldValue(
                        field,
                        value.map(v => v.value),
                    );
                }}
                limitTags={1}
                autoHighlight
                filterSelectedOptions
                variant="standard"
                ChipProps={{ size: 'small' }}
                disableCloseOnSelect
            />
        </FormControl>
    );
}

export function getUpdatedStatus(
    toggledStatus: StatusKey,
    isSelected: boolean,
    statusFieldValue: string[],
): StatusKey[] {
    const updatedStatus = isSelected
        ? statusFieldValue.concat(toggledStatus)
        : statusFieldValue.filter(v => v !== toggledStatus);

    // If selecting not_applied, de-select everything else.
    // Conversely, if selecting something else, remove not_applied from the list.
    if (toggledStatus === 'not_applied' && isSelected) {
        return ['not_applied'];
    }
    if (updatedStatus.includes('not_applied')) {
        updatedStatus.splice(updatedStatus.indexOf('not_applied'), 1);
    }

    const topLevelStatus =
        toggledStatus in StatusOptionsById ? StatusOptionsById[toggledStatus as keyof typeof StatusOptionsById] : null;

    // If toggling a top-level status, ensure all sub-statuses are also toggled
    if (topLevelStatus) {
        if (isSelected) {
            topLevelStatus.children.forEach(c => updatedStatus.push(c.id));
        } else {
            topLevelStatus.children.forEach(c => {
                const index = updatedStatus.indexOf(c.id);
                if (index !== -1) updatedStatus.splice(index, 1);
            });
        }
    } else {
        const parentTopLevelOption = StatusOptions.find(o => o.children.some(c => c.id === toggledStatus));

        if (isSelected) {
            // If adding a sub-status, ensure the top-level status is also added
            if (!updatedStatus.includes(parentTopLevelOption!.id)) {
                updatedStatus.push(parentTopLevelOption!.id);
            }
        } else {
            // If removing a sub-status, check if the top-level status should also be removed
            const subStatuses = parentTopLevelOption!.children.map(c => c.id);
            if (!subStatuses.some(c => updatedStatus.includes(c))) {
                updatedStatus.splice(updatedStatus.indexOf(parentTopLevelOption!.id), 1);
            }
        }
    }

    return updatedStatus as StatusKey[];
}

export function getStatusLabel(status: StatusKey[]) {
    if (!status.length) return statusPlaceholder;

    // If only one status is selected, and all its sub-statuses are selected, show it as the label
    const selectedTopLevelStatuses = StatusOptions.filter(o => status.includes(o.id));
    if (selectedTopLevelStatuses.length === 1) {
        const allSubStatusesSelected = !!(
            StatusOptionsById[selectedTopLevelStatuses[0].id].children as unknown as { id: string; label: string }[]
        ).every(c => status.includes(c.id as StatusKey));

        if (allSubStatusesSelected) return selectedTopLevelStatuses[0].label;
    }

    // Otherwise just show a count, excluding top-level statuses with children
    const statusLength = status.filter(
        s => !StatusOptionsById[s as keyof typeof StatusOptionsById]?.children?.length,
    ).length;

    return `${statusLength} Statuses Selected`;
}
