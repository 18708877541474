import { useForm, yupResolver } from 'FrontRoyalReactHookForm';
import { useContext, useState, useEffect } from 'react';
import { useAngularContext } from 'AngularContext';
import AdminTableContext from './AdminTableContext';

/*
    This function is used when setting up an edit form for an AdminTable.

    It returns
    - formFunctions - The result of the useForm hook
    - onSubmit - callback function to be passed into the form
    - destroy - callback function that will destroy the record

*/
export default function useRecordFromAdminTable(args) {
    const { fromForm, toForm, validationSchema, metaData } = args;
    const { record, collection, onRecordUpdated, onRecordDeleted, setRecordId } = useContext(AdminTableContext);
    const [defaultValuesSet, setDefaultValuesSet] = useState(false);
    const injector = useAngularContext();

    const formFunctions = useForm({
        mode: 'all',
        defaultValues: null,
        resolver: validationSchema ? yupResolver(validationSchema) : undefined,
        reValidateMode: 'onChange',
    });

    useEffect(() => {
        // To see why toForm needs to be async, and why it needs to take an injector,
        // see StudentNetworkEvent/formFieldConversions.js -> accessGroups
        toForm(record.asJson(), { injector }).then(values => {
            formFunctions.reset(values);
            setDefaultValuesSet(true);
        });
    }, [toForm, formFunctions.reset]);

    async function onSubmit(values) {
        let _metadata = metaData || {};
        if (metaData && typeof metaData === 'function') {
            _metadata = metaData();
        }

        const $http = injector.get('$http');
        const formData = new FormData();
        const updates = {};

        const fromValues = await fromForm(values, { injector });
        Object.keys(fromValues).forEach(key => {
            const val = fromValues[key];
            if (val && val.constructor && val.constructor.name === 'File') {
                formData.append(key, val);
            } else {
                updates[key] = val;
            }
        });

        formData.append('record', JSON.stringify(updates));
        formData.append('meta', JSON.stringify(_metadata));

        const isNew = !updates.id;
        const baseUrl = `${window.ENDPOINT_ROOT}/api/${collection}`;
        const url = isNew ? `${baseUrl}.json` : `${baseUrl}/${updates.id}.json`;
        const request = {
            method: isNew ? 'POST' : 'PUT',
            url,
            data: formData,
            headers: {
                Accept: 'application/json',

                // On web we have to set Content-type to undefined here. When we
                // do that, the content-type ends up getting set to multipart/form-data
                // with an appropriate boundary due to $http and the browser fetch implementation.
                // But because capacitor overloads fetch, we need to explicitly set the header
                'Content-type': window.CORDOVA ? `multipart/form-data; boundary=--${Date.now()}` : undefined,
                // Note that this code is not currently ever going to be bundled in capacitor but for the sake of consistency
                // I added the change here too
            },
        };

        const response = await $http(request);
        let result;
        try {
            result = response.data.contents[collection][0];
        } catch (e) {
            throw new Error('No result found in response.');
        }
        const updatedRecord = {
            ...updates,
            ...result,
        };
        const updatedDefaultValues = await toForm(updatedRecord, { injector });
        formFunctions.reset(updatedDefaultValues);
        onRecordUpdated(updatedRecord);
        if (isNew) {
            setRecordId(updatedRecord.id);
        }
    }

    function destroy(event) {
        const $http = injector.get('$http');
        event.preventDefault();
        if (window.confirm('Are you sure you want to delete?')) {
            const baseUrl = `${window.ENDPOINT_ROOT}/api/${collection}`;
            const url = `${baseUrl}/${record.id}.json`;
            const request = {
                method: 'DELETE',
                url,
                headers: {
                    Accept: 'application/json',
                },
            };

            $http(request).then(onRecordDeleted);
        }
    }

    function duplicate() {
        formFunctions.setFieldValue('id', null);
        setRecordId('create');
    }

    // Since toForm can be asynchronous, there may not be defaultValues yet. In that case,
    // we should not yet render the form
    if (!defaultValuesSet) return null;

    return {
        formFunctions,
        onSubmit,
        destroy,
        duplicate,
    };
}
